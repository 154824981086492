.admin-email-drafter {
    padding: 20px;
}

.admin-email-drafter h3 {
    margin-bottom: 20px;
}

.status-message {
    color: green;
    margin-bottom: 10px;
}

.email-drafter-form .form-group {
    margin-bottom: 20px;
}

.email-drafter-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.email-drafter-form input[type="text"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.send-email-button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.send-email-button:hover {
    background-color: #0056b3;
}