.skeleton {
    animation: pulse 1.5s infinite;
    background-color: #f0f0f0;
}

.skeleton-image {
    width: 100%;
    height: 200px;
    background-color: #e6e6e6;
}

.skeleton-text {
    height: 20px;
    background-color: #e6e6e6;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-title {
    width: 60%;
    height: 24px;
}

.skeleton-description {
    width: 80%;
    height: 16px;
}

.skeleton-price {
    width: 40%;
    height: 20px;
}

.skeleton-button {
    width: 50%;
    height: 36px;
    background-color: #e6e6e6;
    border-radius: 5px;
    margin-top: 1rem;
}

@keyframes pulse {
    0% {
        background-color: #f0f0f0;
    }

    50% {
        background-color: #e0e0e0;
    }

    100% {
        background-color: #f0f0f0;
    }
}