.double-sided-divider {
    position: relative;
    background-image: url('../assets/images/background.webp');
    background-size: cover;
    background-position: center;
    padding: 100px 0; /* Increased padding to add more space between dividers */
}

.divider {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.top-divider {
    top: -1px; /* Slight adjustment to ensure no gap at the top */
}

.bottom-divider {
    bottom: -1px; /* Slight adjustment to ensure no gap at the bottom */
    transform: rotate(180deg);
}

.divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.divider .shape-fill {
    fill: #FFFFF8;
}

/* Add some content between dividers for visual spacing */
.double-sided-divider::after {
    content: '';
    display: block;
    height: var(--divider-distance, 100px); /* Use CSS variable for distance */
}
