.base-stations {
    margin-top: 1rem;
}

.create-form, .base-stations-table {
    margin-bottom: 2rem;
}

.create-form div {
    margin-bottom: 0.5rem;
}

.create-form label {
    display: block;
    margin-bottom: 0.2rem;
}

.create-form input {
    width: 100%;
    padding: 0.4rem;
    box-sizing: border-box;
}

.create-form button {
    padding: 0.5rem 1rem;
    background-color: #4CAF50;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.create-form button:hover {
    background-color: #45a049;
}

.base-stations-table {
    width: 100%;
    border-collapse: collapse;
}

.base-stations-table th, .base-stations-table td {
    border: 1px solid #ddd;
    padding: 0.8rem;
    text-align: left;
}

.base-stations-table th {
    background-color: #f2f2f2;
}

.base-stations-table button {
    margin-right: 0.5rem;
    padding: 0.3rem 0.6rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.base-stations-table button:hover {
    opacity: 0.8;
}

.message {
    color: green;
    margin-bottom: 1rem;
}