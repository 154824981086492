.cart {
  padding: 1.5rem;
  background-color: #fff;
  max-height: calc(80vh - 72px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
}

.cart h2 {
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
  color: #4a4a4a;
  text-align: center;
}

.cart ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  width: 100%;
}

.cart li {
  display: grid;
  grid-template-columns: 2fr 80px 100px;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 12px;
  transition: background-color 0.2s ease;
  width: 100%;
  box-sizing: border-box;
}

.cart li:hover {
  background-color: #f0f0f0;
}

.cart li span {
  font-size: 1rem;
}

.cart li span:first-child {
  padding-right: 0.5rem;
}

.cart li span:nth-child(2) {
  font-weight: bold;
  color: #4a4a4a;
  text-align: right;
}

.cart button {
  background-color: #7C9369;
  color: white;
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.cart button:hover {
  background-color: #6a7f59;
  transform: translateY(-1px);
}

.cart button:active {
  transform: translateY(0);
}

.clear-button {
  background-color: #d9534f !important;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem !important;
  padding: 1rem !important;
}

.clear-button:hover {
  background-color: #c9302c !important;
}

.checkout-button {
  background-color: #5cb85c !important;
  width: 100%;
  font-size: 1.1rem !important;
  padding: 1rem !important;
  font-weight: bold;
}

.checkout-button:hover {
  background-color: #449d44 !important;
}

/* Empty cart state */
.cart:empty {
  padding: 2rem;
  text-align: center;
  color: #666;
}

/* Empty cart message */
.cart h2:only-child {
  margin: 2rem 0;
  color: #888;
  font-size: 1.2rem;
}

/* Scrollbar styling */
.cart::-webkit-scrollbar {
  width: 8px;
}

.cart::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.cart::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.cart::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .cart {
    padding: 1rem;
  }

  .cart li {
    grid-template-columns: 1fr 80px;
    gap: 0.8rem;
    padding: 0.8rem;
  }

  .cart li button {
    grid-column: span 2;
    margin-top: 0.5rem;
  }

  .cart button {
    padding: 0.7rem 1rem;
    font-size: 0.9rem;
  }
}
