.contact-us-page {
    max-width: 600px;
    margin: 4rem auto;
    padding: 0;
}

.register-interest-section {
    background-color: rgba(255, 252, 247, 0.95);
    padding: 2.5rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.register-interest-section h2 {
    font-family: inherit;
    color: #4a4a4a;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.register-interest-section p {
    font-family: inherit;
    color: #4a4a4a;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: center;
}

.register-interest-form {
    display: flex;
    gap: 1rem;
    max-width: 500px;
    margin: 0 auto;
}

.register-interest-form input {
    flex: 1;
    padding: 1rem;
    border: 2px solid #7c9369;
    border-radius: 12px;
    font-family: inherit;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
}

.register-interest-form input:focus {
    outline: none;
    border-color: #7c9369;
}

.register-interest-form button {
    padding: 1rem 2rem;
    background-color: #7c9369;
    color: white;
    border: none;
    border-radius: 12px;
    font-family: inherit;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.register-interest-form button:hover {
    background-color: #6b7f5a;
}

/* Contact Form Section */
.contact-section {
    background-color: rgba(255, 252, 247, 0.95);
    padding: 2.5rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact-us-page h2 {
    font-family: inherit;
    color: #4a4a4a;
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.contact-us-page h3 {
    font-family: inherit;
    color: #4a4a4a;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    font-weight: normal;
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

.contact-us-form input,
.contact-us-form textarea {
    width: 100%;
    padding: 1rem;
    border: 2px solid #7c9369;
    border-radius: 12px;
    font-family: inherit;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    background-color: white;
    box-sizing: border-box;
}

.contact-us-form input:focus,
.contact-us-form textarea:focus {
    outline: none;
    border-color: #7c9369;
}

.contact-us-form textarea {
    height: 150px;
    resize: vertical;
}

.contact-us-form button {
    padding: 1rem 2rem;
    background-color: #7c9369;
    color: white;
    border: none;
    border-radius: 12px;
    font-family: inherit;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0.5rem;
    width: 100%;
}

.contact-us-form button:hover {
    background-color: #6b7f5a;
}

.success-message {
    color: #4a4a4a;
    background-color: rgba(124, 147, 105, 0.2);
    padding: 1rem;
    border-radius: 12px;
    margin-top: 1.5rem;
    font-family: inherit;
}

.error-message {
    color: #d32f2f;
    background-color: rgba(211, 47, 47, 0.1);
    padding: 1rem;
    border-radius: 12px;
    margin-top: 1.5rem;
    font-family: inherit;
}

@media (max-width: 768px) {
    .contact-us-page {
        margin: 2rem auto;
        padding: 0 1rem;
        width: 100%;
        box-sizing: border-box;
    }

    .register-interest-form {
        flex-direction: column;
        width: 100%;
    }
    
    .register-interest-form input {
        margin-bottom: 1rem;
    }

    .register-interest-section,
    .contact-section {
        padding: 1.5rem;
        width: 100%;
        box-sizing: border-box;
    }

    .contact-us-form {
        padding: 0;
    }
}
