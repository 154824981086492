/* General page styling */
.home-page {
    text-align: center;
    color: #000000;
}

/* Hero Section: Forest Background and Title */
.hero-section {
    position: relative;
    height: 90vh;
    background: url('../assets/images/background.webp') no-repeat center 60%;
    background-size: cover;
    background-attachment: scroll;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.hero-content {
    margin-top: 25vh;
    color: #000000;
    text-align: left;
    padding: 20px;
}

.hero-content h1 {
    font-size: 4rem;
    margin: 0px;
}

.hero-content p {
    font-size: 2rem;
    margin-top: 0px;
    margin-bottom: 20px;
}

.hero-buttons {
    display: flex;
    justify-content: left;
    gap: 20px;
}

.hero-shop-btn {
    background-color: #7C9369BF;
    color: black;
    padding: 15px 40px;
    border: none;
    border-radius: 50px;
    text-decoration: none;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
}

.hero-learn-more-btn {
    background-color: #7C9369BF;
    color: black;
    padding: 15px 40px;
    border: none;
    border-radius: 50px;
    text-decoration: none;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.hero-login-btn {
    color: black;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.5rem;
}

.hero-shop-btn:hover,
.hero-learn-more-btn:hover {
    background-color: #566d43bf;
}

.hero-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: url('../assets/images/curly-divider.svg') no-repeat center bottom;
    background-size: cover;
    transform: rotate(180deg);
    pointer-events: none;
}

.how-it-works-section h2 {
    font-size: 3.5rem;
    margin-bottom: 20px;
}

.how-it-works-section hr {
    border: none;
    height: 2px;
    width: 80%;
    margin: 0 auto 40px;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5), transparent);
}

.how-it-works-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    padding-bottom: 100px;
}

.step {
    display: flex;
    align-items: center;
    text-align: left;
}

.step-info,
.step-image-headphones,
.step-image-right,
.step-image-left {
    flex: 1;
}

.step-info h3 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.step-info p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.step-btn {
    display: inline-block;
    background-color: #7C9369;
    color: white;
    padding: 10px 50px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.step-btn:hover {
    background-color: #566d43;
}

.step-image-headphones {
    height: 300px;
    display: flex;
    position: relative;
    justify-content: right;
    right: 70px;
}

.step-image-right {
    height: 300px;
    display: flex;
    position: relative;
    left: 40px;
    top: 70px;
}

.step-image-left {
    height: 300px;
    display: flex;
    position: relative;
    justify-content: right;
    right: 40px;
    top: 70px;
}

.wave-divider {
    position: relative;
    height: 150px;
    width: 100%;
    overflow: hidden;
}

.features-section {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
}

.features-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
}

.features-image {
    width: 50%;
    border-radius: 50px;
    overflow: hidden;
    height: fit-content;
}

.features-image img {
    width: 100%;
    display: block;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    transition: all 0.3s ease;
}

.features-text {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-item {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 20px;
    transition: transform 0.2s ease;
}

.feature-item:hover {
    transform: translateX(10px);
}

.features-text h2 {
    font-size: 2.5rem;
    margin-bottom: 5px;
}

.features-text p {
    font-size: 1.2rem;
    margin-top: 0px;
}

.home-character-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 20px;
}

.meet-the-character-text {
    font-size: 3rem;
}

.home-character-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-character {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    text-align: left;
}

.home-character img {
    width: 150px;
    object-fit: contain;
    margin-right: 50px;
}

.home-character-info {
    flex: 1;
}

.home-character h3 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
}

.home-character p {
    font-size: 1.4rem;
    color: #666;
    margin-bottom: 20px;
}

.home-character-buy-btn {
    background-color: #7C9369BF;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    display: inline-block;
}

.home-character-buy-btn:hover {
    background-color: #566d43bf;
}

.map-location-section {
    width: 100%;
    box-sizing: border-box;
}

.map-location-section h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.map-location-content {
    width: 100%;
    height: 400px;
    position: relative;
}

.map-image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.map-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(5px);
}

.visit-map-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #7C9369;
    color: black;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.visit-map-btn:hover {
    background-color: #566d43;
}

.desktop-only {
    display: block;
}

.mobile-feature-image {
    display: none;
}

.mobile-feature-image img {
    width: 100%;
    border-radius: 25px;
    margin-bottom: 15px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-content p {
        font-size: 1.5rem;
    }

    .hero-buttons {
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }

    .hero-shop-btn, .hero-login-btn, .hero-learn-more-btn {
        text-align: center;
        font-size: 1.2rem;
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
    }

    .how-it-works-section h2 {
        font-size: 2.5rem;
    }

    .step {
        flex-direction: column;
        text-align: center;
    }

    .step-info h3 {
        font-size: 2rem;
    }

    .step-info p {
        font-size: 1rem;
    }

    .step-image-headphones, .step-image-right, .step-image-left {
        display: none;
    }

    .features-content {
        flex-direction: column;
    }

    .features-image {
        width: 100%;
        margin-bottom: 20px;
    }

    .features-text {
        margin-left: 0;
        text-align: center;
    }

    .features-text h2 {
        font-size: 2rem;
    }

    .features-text p {
        font-size: 1rem;
    }

    .home-character {
        flex-direction: column;
        text-align: center;
    }

    .home-character img {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .home-character h3 {
        font-size: 2rem;
    }

    .home-character p {
        font-size: 1.2rem;
    }

    .map-location-section h2 {
        font-size: 2rem;
    }

    .visit-map-btn {
        font-size: 1rem;
        padding: 10px 20px;
    }

    .desktop-only {
        display: none;
    }

    .mobile-feature-image {
        display: block;
        padding: 0 50px;
    }

    .mobile-feature-image img {
        width: 100%;
        border-radius: 25px;
        margin-bottom: 15px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }

    .feature-item {
        margin-bottom: 40px;
    }

    .feature-item:hover {
        transform: none;
    }
}
