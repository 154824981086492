.shop-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 0;
  padding-top: 10vh;
}

.shop-content {
  width: 90%;
  max-width: 1200px;
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.shop-header {
  text-align: center;
  margin-bottom: 2rem;
}

.shop-character-heading h2 {
  text-align: left;
  margin-bottom: 2rem;
}

.shop-header h1 {
  color: #4a4a4a;
  font-size: 2.5rem;
  font-weight: bold;
}

.character-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
}

.character-card {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.character-image {
  height: 200px;
  background-color: #e6eee6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image {
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.4));
}

.product-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.product-buttons button {
  flex: 1;
  margin: 0 0.2rem;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-buttons button:first-child {
  background-color: #5cb85c;
  color: white;
}

.product-buttons button:last-child {
  background-color: #007bff;
  color: white;
}

.product-buttons button:hover {
  opacity: 0.8;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background-color: #d1e0d1;
}

.character-info {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.character-info h3 {
  margin: 0 0 0.5rem 0;
  color: #4a4a4a;
  font-size: 1.2rem;
  font-weight: bold;
}

.price {
  font-weight: bold;
  color: #4a4a4a;
  font-size: 1.1rem;
  margin: 0;
}

.description {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
}

.character-info button {
  padding: 0.5rem 1rem;
  background-color: #7C9369;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.character-info button:hover {
  background-color: #566d43;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-image img {
  width: 100%;
  border-radius: 10px;
}

.modal-info {
  margin-top: 1rem;
}

.price {
  font-weight: bold;
  color: #4a4a4a;
  font-size: 1.1rem;
}

.shop-stump-heading {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.shop-stump-heading h2 {
  color: #4a4a4a;
  font-size: 2rem;
  font-weight: bold;
}

.stump-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0;
  position: relative;
  padding: 0 2rem;
}

.stump-image-container {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stump-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.music-notes {
  width: 150px;
  height: auto;
  opacity: 0.8;
}

.left-notes {
  transform: scaleX(-1) rotate(-15deg);
}

.right-notes {
  transform: rotate(15deg);
}

.stump-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #e6eee6;
  border-radius: 15px;
  overflow: hidden; 
}

.stump-form-container p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #4a4a4a;
}

.stump-order-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-row {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.stump-order-form input,
.stump-order-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
  font-family: inherit;
  background-color: #ffffff;
  box-sizing: border-box;
}

.stump-order-form textarea {
  height: 100px;
  resize: vertical;
}

.submit-button {
  padding: 0.75rem 1.5rem;
  background-color: #7C9369;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
  font-family: inherit;
  align-self: center;
}

.submit-button:hover {
  background-color: #566d43;
}

@media (max-width: 768px) {
  .shop-page {
    padding: 1rem 0;
  }

  .character-grid {
    width: 70%;
    margin: 0 auto;
  }
  
  .character-info button {
    padding: 1rem 1.5rem;
    border-radius: 25px;
  }

  .description {
    display: none;
  }

  .character-image img {
    height: 150px;
  }

  .character-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .stump-section {
    flex-direction: column;
    gap: 1rem;
  }

  .music-notes {
    width: 100px;
  }

  .left-notes {
    align-self: flex-start;
  }

  .right-notes {
    align-self: flex-end;
  }
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }
  
  .stump-form-container {
    padding: 1.5rem;
  }
}

.success-message {
  color: green;
  font-weight: bold;
  text-align: center;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}
