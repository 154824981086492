.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    max-width: 400px;
    margin: auto;
}

.login-content h1 {
    font-size: 2.5rem;
    color: #4a4a4a;
    margin-bottom: 0.5rem;
}

.login-content h2 {
    font-size: 1.2rem;
    color: #6a6a6a;
    margin-bottom: 2rem;
    text-align: center;
}

.login-content form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.login-content input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-content .login-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    background-color: #4CAF50;
    color: white;
    transition: background-color 0.3s;
}

.login-content .login-button:hover {
    background-color: #45a049;
}

.login-content .shop-link {
    margin-top: 1rem;
    text-decoration: none;
    color: #4a4a4a;
    font-weight: bold;
}

.login-content .shop-link:hover {
    text-decoration: underline;
}