.admin-interests {
    padding: 20px;
}

.interests-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.interests-table th,
.interests-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.interests-table th {
    background-color: #f2f2f2;
}

.message {
    color: red;
    font-weight: bold;
}