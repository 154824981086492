.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.modal h4 {
    margin-top: 0;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.modal-actions button {
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions button[type="submit"] {
    background-color: #4CAF50;
    color: white;
}

.modal-actions button[type="button"] {
    background-color: #f44336;
    color: white;
}