.success-page {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f4f0;
    color: #333;
    padding: 10px;
    max-width: max-content;
    margin: auto;
    margin-top: 10vh;
    text-align: center;
    min-height: fit-content;
    border-radius: 30px;
}