.users {
    margin-top: 1rem;
}

.users-table {
    width: 100%;
    border-collapse: collapse;
}

.users-table th, .users-table td {
    border: 1px solid #ddd;
    padding: 0.8rem;
    text-align: left;
}

.users-table th {
    background-color: #f2f2f2;
}

.users-table button {
    margin-right: 0.5rem;
    padding: 0.3rem 0.6rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.users-table button:hover {
    opacity: 0.8;
}

.users-table button:nth-child(1) {
    background-color: #4CAF50;
    color: white;
}

.users-table button:nth-child(2) {
    background-color: #f44336;
    color: white;
}

.message {
    color: green;
    margin-bottom: 1rem;
}