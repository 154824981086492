.map-page {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    box-sizing: border-box;
}

.map-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.map-subtitle {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
    font-weight: normal;
}

.map-content {
    width: 100%;
    margin: 100px;
    max-width: 90%;
    background-color: #f5f3e8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.map-container {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.leaflet-container {
    height: 70vh;
    width: 100%;
    max-height: 600px;
}

.location-count {
    justify-items: center;
}

.location-counts {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.count {
    text-align: center;
    flex: 1;
}

.count h3 {
    font-size: 2.5em;
    color: #4a4a4a;
    margin-bottom: 5px;
    font-weight: bold;
}

.count p {
    font-size: 1em;
    color: #666;
    font-weight: normal;
}

@media (max-width: 768px) {
    .map-page {
        padding: 10px;
    }

    .map-content {
        max-width: 100%;
        padding: 15px;
    }

    .map-title {
        font-size: 2em;
    }

    .map-subtitle {
        font-size: 1.5em;
    }

    .leaflet-container {
        height: 50vh;
    }

    .location-counts {
        flex-direction: column;
    }

    .count {
        margin-bottom: 20px;
    }

    .count h3 {
        font-size: 2em;
    }

    .leaflet-control-container {
        display: none;
    }
    
    .map-content {
      margin-top: 0;
    }
}

.register-interest-section {
    background-color: #e6eee6;
    padding: 2rem;
    border-radius: 10px;
    max-width: 800px;
    margin-top: 6vh;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.register-interest-section h2 {
    color: #4a4a4a;
    margin-bottom: 1rem;
}

.register-interest-section p {
    color: #333;
    margin-bottom: 1.5rem;
}

.register-interest-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.register-interest-form input {
    padding: 0.75rem;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.register-interest-form button {
    padding: 0.75rem 1.5rem;
    background-color: #7C9369;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.register-interest-form button:hover {
    background-color: #566d43;
}

.success-message {
    color: green;
    font-weight: bold;
}

.error-message {
    color: red;
    font-weight: bold;
}

.leaflet-control-zoom {
    z-index: 500;
    /* Ensure it's below the navbar which typically has a higher z-index */
}

@media (max-width: 768px) {
    .leaflet-control-zoom {
        bottom: 60px;
        /* Adjust based on navbar height */
        right: 10px;
    }
}