.admin-panel {
    padding: 2rem;
    padding-top: 10vh;
    background-color: #f9f9f9;
}

.admin-tabs {
    display: flex;
    margin-bottom: 1rem;
}

.admin-tabs button {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    border: none;
    background-color: #ddd;
    cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s;
}

.admin-tabs button.active,
.admin-tabs button:hover {
    background-color: #bbb;
}

.admin-content {
    background-color: #fff;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
