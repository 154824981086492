.wifi-setup-page {
    width: 100%;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh 20px;
    box-sizing: border-box;
}

.wifi-setup-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 30px;
    font-weight: normal;
    text-align: center;
}

.wifi-setup-content {
    width: 100%;
    max-width: 800px;
    background-color: #f5f3e8;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: center;
}

.wifi-setup-description {
    font-size: 1.1em;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.5;
    text-align: center;
}

.wifi-setup-steps {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 30px 0;
    text-align: center;
}

.wifi-step {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.wifi-step span {
    text-align: center;
}

.wifi-icon {
    width: 40px;
    height: 40px;
    background-color: #6b8e4e;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.wifi-network-button {
    background-color: #6b8e4e;
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.wifi-login-button {
    background-color: #6b8e4e;
    color: white;
    padding: 8px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    margin: 10px auto;
}

.wifi-confirmation {
    color: #333;
    margin-top: 30px;
    text-align: center;
}

@media (max-width: 768px) {
    .wifi-setup-page {
        padding: 2vh 15px;
    }

    .wifi-setup-content {
        padding: 20px;
    }

    .wifi-setup-title {
        font-size: 2em;
    }
}
