.analytics-dashboard {
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.analytics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.date-filters {
    display: flex;
    gap: 10px;
}

.date-filters input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.analytics-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.summary-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.summary-card h3 {
    margin: 0 0 10px 0;
    font-size: 0.9rem;
    color: #666;
}

.summary-card p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.analytics-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 30px;
    margin-bottom: 30px;
}

.chart-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container h3 {
    margin: 0 0 20px 0;
    font-size: 1.1rem;
    color: #333;
    text-align: center;
}

.analytics-details {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
}

.analytics-details h3 {
    margin: 0 0 20px 0;
    font-size: 1.1rem;
    color: #333;
}

.events-table {
    overflow-x: auto;
}

.events-table table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

.events-table th,
.events-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.events-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
}

.events-table tr:hover {
    background-color: #f8f9fa;
}

@media (max-width: 768px) {
    .analytics-charts {
        grid-template-columns: 1fr;
    }
    
    .chart-container {
        min-height: 300px;
    }
    
    .analytics-summary {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .analytics-summary {
        grid-template-columns: 1fr;
    }
    
    .analytics-header {
        flex-direction: column;
        gap: 15px;
    }
}
