@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Love Ya Like A Sister', sans-serif;
    background-color: #FFFFF8;
    height: 100%;
}

button {
    font-family: inherit;
}

.layout {
    background-image: url('../assets/images/background.webp');
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.layout.no-background {
    background: none; 
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgba(220, 216, 200, 0.5);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;  
    color: #4a4a4a;
}

.navbar-logo a {
    text-decoration: none;
    color: inherit;
}

.navbar-right {
    display: flex;
    align-items: center;
}

.navbar-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navbar-links li {
    font-size: 1.2rem;
    margin: 0 1rem;
}

.navbar-links a {
    text-decoration: none;
    color: #4a4a4a;
}

.navbar-cart {
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    color: #4a4a4a;
    transition: color 0.3s ease;
    margin-left: 1rem;
}

.navbar-cart:hover {
    color: #000;
}

.cart-dropdown {
    position: fixed;
    top: -100%;
    right: 0;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: top 0.3s ease;
    z-index: 999;
    max-height: 80vh;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
}

.cart-dropdown.open {
    top: 72px; /* Height of navbar + padding */
}

.navbar-profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.footer {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1rem 2rem;
    margin-top: auto;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content p {
    color: #4a4a4a;
    font-size: 0.9rem;  
    margin: 0;
}

.content {
    padding-top: 72px; /* Height of navbar */
}

.navbar-hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.navbar-hamburger span {
    height: 3px;
    width: 25px;
    background-color: #4a4a4a;
    margin: 2px 0;
    transition: all 0.3s ease;
}

.learn-more-btn {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(220, 216, 200, 0.8);
    border: none;
    padding: 1rem 2rem;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    color: #4a4a4a;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.learn-more-btn:hover {
    background-color: rgba(220, 216, 200, 0.9);
    transform: translateX(-50%) translateY(-5px);
}

.learn-more-btn svg {
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-5px);
    }
    60% {
        transform: translateY(-3px);
    }
}

@media (max-width: 768px) {
    .navbar-right {
        gap: 0.5rem;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: rgba(220, 216, 200, 0.9);
        padding: 1rem;
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links li {
        margin: 0.5rem 0;
    }

    .navbar-hamburger {
        display: flex;
    }

    .cart-dropdown {
        width: 100%;
    }
    
    .layout:not(.no-background)::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/images/background.webp');
        background-color: transparent;
        background-size: cover;
        background-position: center center;
        z-index: -1;
    }
}

.hero-section {
    position: relative;
    height: 90vh;
    background: url('../assets/images/background.webp') no-repeat center 60%;
    background-size: cover;
    background-attachment: scroll;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

@media (max-width: 768px) {
    .hero-section {
        background-attachment: scroll;
        background-position: center center;
    }
}
