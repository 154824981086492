.api-keys {
    margin-top: 1rem;
}

.generate-api-key {
    margin-bottom: 2rem;
}

.generate-api-key select {
    padding: 0.5rem;
    margin-right: 0.5rem;
}

.generate-api-key button {
    padding: 0.5rem 1rem;
    background-color: #2196F3;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.generate-api-key button:hover {
    background-color: #1976D2;
}

.api-keys-table {
    width: 100%;
    border-collapse: collapse;
}

.api-keys-table th, .api-keys-table td {
    border: 1px solid #ddd;
    padding: 0.8rem;
    text-align: left;
}

.api-keys-table th {
    background-color: #f2f2f2;
}

.api-keys-table button {
    padding: 0.3rem 0.6rem;
    border: none;
    background-color: #f44336;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.api-keys-table button:hover {
    opacity: 0.8;
}

.message {
    color: green;
    margin-bottom: 1rem;
}