.admin-characters {
    padding: 20px;
}

.admin-message {
    color: green;
    margin-bottom: 10px;
}

.admin-create-form,
.admin-assign-form,
.admin-add-visit-form {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.admin-create-form h4,
.admin-assign-form h4,
.admin-add-visit-form h4 {
    margin-bottom: 10px;
}

.admin-create-form div,
.admin-assign-form div,
.admin-add-visit-form div {
    margin-bottom: 10px;
}

.admin-create-form label,
.admin-assign-form label,
.admin-add-visit-form label {
    display: block;
    margin-bottom: 5px;
}

.admin-create-form input,
.admin-assign-form select,
.admin-add-visit-form select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.admin-characters-table,
.admin-visits-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.admin-characters-table th,
.admin-characters-table td,
.admin-visits-table th,
.admin-visits-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.admin-characters-table th,
.admin-visits-table th {
    background-color: #f2f2f2;
}

button {
    padding: 6px 12px;
    margin-right: 5px;
    cursor: pointer;
}

.admin-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-modal {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 60%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.admin-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}